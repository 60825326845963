import BoxArrowUpIcon from '@/components/atoms/Icon/svg/BoxArrowUpIcon'
import Color from '@/enums/Color'
import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
    body: {
        textAlign: 'center',
        fontSize: 14,
        color: '#222222',
        lineHeight: 1.5,
    },
    link: {
        color: Color.primaryN,
        cursor: 'pointer',
    },
})

export default function NotifyValidateEmail() {
    const classes = useStyle()
    return (
        <div className={classes.body}>
            メールアドレスは変更できません。
            <br /> メールアドレスを変更したい場合は、新しくユーザーを追加してください。
        </div>
    )
}
